.footer {
    margin: 66px 0 60px;
    color: #545454;
    font-size: 18px;
}

@media screen and (max-width: 680px) {
    .footer {
        font-size: 14px;
        padding-left: calc((100vw - 282px) / 2);
    }
}