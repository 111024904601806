.popup__button-save {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 400;

    background-color: black;
    color: white;
    min-height: 50px;
    border-radius: 2px;
    border: none;

    
    margin-top: 38px;
    outline: none;
    transition: opacity linear 0.5s;
}
  
.popup__button-save:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.8);
}
  
  @media (max-width: 480px) {
    .popup__button-save {
        font-size: 14px;
        height: 46px;
        padding: 0;

        margin-top: 35px;
    }
  }