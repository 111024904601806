.profile__about {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.22;
    
    color: #FFFFFF;

    flex: 0 1 700px;
    text-overflow: ellipsis;
    
    overflow: hidden;
    max-width: 372px;
}



@media screen and (max-width: 730px) {
    .profile__about {
        font-size: 14px;
     
        text-align: center;
    }
}