.form__input {
    margin-bottom: 30px;
    width: 100%;
    border: 0;
    border-bottom: 2px solid white;
    padding: 0 0 13px;
    background:none;

    color: white;

    transition: border-bottom 0.4s linear;
    font-size: 14px;
}

.form__input:focus {
    border-bottom: 2px solid orangered;
    outline: none;
}
