.popup__title {
    color: #000000;
    font-weight: 900;
    font-size: 24px;
    line-height: 1.2;
    margin: 0 0 54px;
}

@media (max-width: 550px) {
    .popup__title {
        font-size: 18px;
        margin-bottom: 75px;
    }
}