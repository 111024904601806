.profile__avatar {
    max-width: 120px;
    width: 100%;
    height: 120px;
    border-radius: 50%;
    margin-right: 30px;
    object-fit: cover;
    object-position: center;
}

@media screen and (max-width: 730px) {
    .profile__avatar {
        margin-right: 0;
        margin-bottom: 26px;
    }
}