.popup__button-save_disabled {
    pointer-events: none;
    background: transparent;
    border: 1px solid rgba(0, 0, 1, 0.2);
    box-sizing: border-box;
    border-radius: 2px;

    color: rgba(0, 0, 1, 0.2);
}

