@import url(./__heading/form__heading.css);
@import url(./__input/form__input.css);
@import url(./__submit/form__submit.css);
@import url(./__p/form__p.css);
@import url(./__link/form__link.css);

.form {
    max-width: 358px;
    margin: 60px auto 0;
}

@media screen and (max-width:550px) {
    .form {
        max-width: 260px;
        margin: 40px auto 0;
    }
}