.popup {
    
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.7s, visibility 0s linear 0.6s;
    display: flex;
    position: fixed;
    top:0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(0,0,0, .5);
    align-items: center;
    justify-content: center;


}


