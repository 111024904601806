.popup__image {
    max-width: 75vw;
    max-height: 75vh;
    object-fit: contain;
}

@media screen and (max-width:450px) {
    .popup__image {
        max-width: 100%;
    }
}