.popup__title_type_auth {
    max-width: 358px;
    margin: 32px auto 60px;
    text-align: center;
}

@media screen and (max-width: 550px) {
    .popup__title_type_auth {
        min-width: 244px;
    }
}