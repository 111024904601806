.popup__form {
    display: flex;
    flex-direction: column;
    width: 430px;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
    padding: 36px;
}

@media (max-width: 550px) {
    .popup__form {
      padding: 25px 22px;
    }
}