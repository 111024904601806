.popup__container {
    position: relative;
    display: flex;
    margin: auto;
    

    
    /*height: 330px;*/

    
    
}



@media (max-width: 550px) {
    .popup__container {
      width: 282px;
      /*height: 322px;*/ 
    }
}